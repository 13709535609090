var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.031"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(0),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.032")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.033")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.034")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.035")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.036")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.037")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.038")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.039")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.040")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.041")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.042")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.043")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.044")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.045")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.046")))]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.items, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.statusNm))]),
                _c("td", [_vm._v(_vm._s(item.frtCd))]),
                _c("td", [_vm._v(_vm._s(item.perNm))]),
                _c("td", [_vm._v(_vm._s(item.pcNm))]),
                _c("td", { staticClass: "pr0" }, [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      name: "fix",
                      id: "fix1",
                      disabled: "",
                    },
                    domProps: { checked: item.fixYn },
                  }),
                  _vm._m(1, true),
                ]),
                _c("td", [_vm._v(_vm._s(item.occrCurCd))]),
                _c("td", [_vm._v(_vm._s(item.rton))]),
                _c("td", [_vm._v(_vm._s(item.bseRate))]),
                _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                _c("td", [_vm._v(_vm._s(item.cgoTypCd))]),
                _c("td", { staticClass: "pr0" }, [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      name: "svan",
                      id: "svan1",
                      disabled: "",
                    },
                    domProps: { checked: item.shprVanYn },
                  }),
                  _vm._m(2, true),
                ]),
                _c("td", { staticClass: "pr0" }, [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      name: "nil",
                      id: "nil1",
                      disabled: "",
                    },
                    domProps: { checked: item.wavYn },
                  }),
                  _vm._m(3, true),
                ]),
                _c("td", [_vm._v(_vm._s(item.occrAmt))]),
                _c("td", [_vm._v(_vm._s(item.payPlcCd))]),
                _c("td", [_vm._v(_vm._s(item.payCstNo))]),
              ])
            }),
            0
          ),
        ]),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "fix1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "svan1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "nil1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
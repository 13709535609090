<template>
  <div v-if="viewFlag">
    <h2 class="content_title">{{ $t('msg.ONEX010P090.031') }}</h2> <!-- B/L FREIGHT -->
    <table class="tbl_col">
      <colgroup>
        <col style="width:6%">
        <col style="width:6%">
        <col style="width:6%">
        <col style="width:8%">
        <col style="width:6%">

        <col style="width:6%">
        <col style="width:6%">
        <col style="width:8%">
        <col style="width:6%">
        <col style="width:6%">

        <col style="width:6%">
        <col style="width:6%">
        <col style="width:8%">
        <col style="width:8%">
        <col style="width:8%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.032') }}</th> <!-- Status -->
          <th>{{ $t('msg.ONEX010P090.033') }}</th> <!-- Code -->
          <th>{{ $t('msg.ONEX010P090.034') }}</th> <!-- Per -->
          <th>{{ $t('msg.ONEX010P090.035') }}</th> <!-- P/C -->
          <th>{{ $t('msg.ONEX010P090.036') }}</th> <!-- FIX -->
          <th>{{ $t('msg.ONEX010P090.037') }}</th> <!-- CUR -->
          <th>{{ $t('msg.ONEX010P090.038') }}</th> <!-- Q'TY -->
          <th>{{ $t('msg.ONEX010P090.039') }}</th> <!-- Rate -->
          <th>{{ $t('msg.ONEX010P090.040') }}</th> <!-- TYPE -->
          <th>{{ $t('msg.ONEX010P090.041') }}</th> <!-- CGO -->
          <th>{{ $t('msg.ONEX010P090.042') }}</th> <!-- S'Van -->
          <th>{{ $t('msg.ONEX010P090.043') }}</th> <!-- Nill -->
          <th>{{ $t('msg.ONEX010P090.044') }}</th> <!-- Amount -->
          <th>{{ $t('msg.ONEX010P090.045') }}</th> <!-- Pay At -->
          <th>{{ $t('msg.ONEX010P090.046') }}</th> <!-- Pay By -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.statusNm }}</td>
          <td>{{ item.frtCd }}</td>
          <td>{{ item.perNm }}</td>
          <td>{{ item.pcNm }}</td>
          <td class="pr0">
            <input type="checkbox" name="fix" id="fix1" :checked="item.fixYn" disabled><label for="fix1"><span class="offscreen"></span></label>
          </td>
          <td>{{ item.occrCurCd }}</td>
          <td>{{ item.rton }}</td>
          <td>{{ item.bseRate }}</td>
          <td>{{ item.cntrTypCd }}</td>
          <td>{{ item.cgoTypCd }}</td>
          <td class="pr0">
            <input type="checkbox" name="svan" id="svan1" :checked="item.shprVanYn" disabled><label for="svan1"><span class="offscreen"></span></label>
          </td>
          <td class="pr0">
            <input type="checkbox" name="nil" id="nil1" :checked="item.wavYn" disabled><label for="nil1"><span class="offscreen"></span></label>
          </td>
          <td>{{ item.occrAmt }}</td>
          <td>{{ item.payPlcCd }}</td>
          <td>{{ item.payCstNo }}</td>
        </tr>
      </tbody>
    </table>
    <br>
  </div>
</template>

<script>
import commons from '@/api/services/commons'

export default {
  name: 'ShippingLogBLDetailBLFreightPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: '',
      items: [],
      statusNm: '',
      perNm: '',
      pcNm: ''
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    console.log('B/L Detail freight Pop')
    this.init()
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    async init () {
      //1.공통코드 초기화
      const codeData = await commons.getCommonsCodesCommon(['01182', '01031', '01032'])
      this.statusNm = codeData['01182']
      this.perNm = codeData['01031']
      this.pcNm = codeData['01032']

      //2.메인데이터 초기화
      const blFreight = this.mainData.blFreight

      if (blFreight && blFreight.length > 0) {
        this.items = blFreight.map((item) => {
          //STATUS 셋팅
          const statusNm = this.statusNm.filter((status) => {
              return status.cd === item.blFrtUpdtCatCd
          })[0]
          item.statusNm = statusNm ? statusNm.cdNm : ''

          //Per 셋팅
          const perNm = this.perNm.filter((per) => {
              return per.cd === item.cntrSzCd
          })[0]
          item.perNm = perNm ? perNm.cdNm : ''

          //P/C 셋팅
          const pcNm = this.pcNm.filter((pc) => {
              return pc.cd === item.frtPncCd
          })[0]
          item.pcNm = pcNm ? pcNm.cdNm : ''

          return item
        })
        this.viewFlag = true
      } else {
        this.viewFlag = false
      }
    }
  }
}
</script>
